
var InputSearchTag = {

    init: function () {
        $('.InputSearchTag .InputSearch').on('keyup', function(e) {
            var searchVal = $(this).val();
            if (searchVal.length > 0) {

                if (e.which == 13) {
                    e.preventDefault();
                    InputSearchTag.addTag(searchVal);
                    $(this).val('');
                }
            }
            InputSearchTag.search();
        });
    },

    addTag: function (val) {
        var tag = $('<span class="InputSearchTag__tag">' + val + '</span>');
        tag[0].addEventListener('click', function() {
            InputSearchTag.deleteTag(tag);
        });
        $('.InputSearchTag__tagCtn').append(tag);
    },

    deleteTag: function (tag) {
        tag.remove();
        InputSearchTag.search();
    },

    search: function () {
        $('.InputSearchTag .InputSearch:not(.InputSearch--custom)').each(function () {
            let values = [];
            // let colSearch = $(this).data('field');

            if ($(this).val().length > 0) values.push($(this).val())

            $('.InputSearchTag__tagCtn .InputSearchTag__tag').each(function () {
                values.push($(this).text())
            })
            let regex = values.join("|");
            $('#atom-data-table').DataTable().column(2).search(regex, true).draw();
        })
    }
}

InputSearchTag.init();

export default InputSearchTag;